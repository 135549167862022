import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { ValueModule } from 'prosumer-app/shared/components/value';
import { SharedModule } from '../../../shared';
import { ScenarioStatusDialogComponent } from './scenario-status-dialog.component';

@NgModule({
  declarations: [ScenarioStatusDialogComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatTableModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    TranslateModule.forChild(),
    FlexLayoutModule,
    SharedModule,
    ValueModule,
  ],
})
export class ScenarioStatusDialogModule {}
