<div class="container" data-testid="simulation-status-dialog">
  <header fxLayout="row" fxLayoutAlign="space-between center">
    <h2 data-testid="status-dialog-title">
      Simulation Status: {{ data.name }}
    </h2>
  </header>
  <br />
  <div class="table-container">
    <mat-table
      matSort
      mat-table
      class="eyes-table"
      data-testid="status-dialog-table"
      [dataSource]="data$ | async"
    >
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef fxFlex="10">Status</mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="10">
          <prosumer-simulation-status
            [status]="element?.run?.status"
          ></prosumer-simulation-status>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="variation">
        <mat-header-cell *matHeaderCellDef>Variation</mat-header-cell>
        <mat-cell *matCellDef="let element" [title]="element.name">{{
          element.name
        }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="launchDate">
        <mat-header-cell *matHeaderCellDef fxFlex="20"
          >Launch Date</mat-header-cell
        >
        <mat-cell *matCellDef="let element" fxFlex="20">{{
          element?.run?.launchDate | date: 'dd MMM yyyy HH:mm'
        }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="duration">
        <mat-header-cell *matHeaderCellDef fxFlex="13"
          >Duration</mat-header-cell
        >
        <mat-cell
          *matCellDef="let element"
          fxFlex="13"
          class="default-content"
          >{{ element.run?.duration | minsTimeConvert }}</mat-cell
        >
      </ng-container>
      <ng-container matColumnDef="tco">
        <mat-header-cell *matHeaderCellDef>TCO</mat-header-cell>
        <mat-cell *matCellDef="let variation">
          <prosumer-value
            [value]="variation.run?.outputValues?.globalTotalCost"
            prefix="kilo"
            unit="kEUR"
          ></prosumer-value>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="co2FinalYear">
        <mat-header-cell *matHeaderCellDef>CO2 (Final Year)</mat-header-cell>
        <mat-cell *matCellDef="let variation">
          <prosumer-value
            [value]="variation.run?.outputValues?.globalFinalCO2Emissions"
            prefix="base"
            unit="kgCO2"
          ></prosumer-value>
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
      ></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>

    <p
      *ngIf="loading$ | async"
      class="table-message"
      data-testid="table-loading"
    >
      {{ 'Generic.messages.loadingData' | translate }}
    </p>
    <p
      *ngIf="(loading$ | async) !== true && (hasData$ | async) !== true"
      class="table-message"
      data-testid="table-no-data"
    >
      {{ 'Generic.messages.noRecordsFound2' | translate }}
    </p>
  </div>

  <div class="action" fxLayout="row" fxLayoutAlign="space-between center">
    <button mat-flat-button mat-button-base type="button" (click)="onClose()">
      {{ 'Generic.labels.close' | translate }}
    </button>
  </div>
</div>
