import { InjectionToken } from '@angular/core';

export interface ScenarioListConfig {
  readonly columns: ScenarioListColumn[];
}

export enum ScenarioListColumn {
  name = 'name',
  runHistory = 'runHistory',
  status = 'status',
  createdDate = 'createdDate',
  launchDate = 'launchDate',
  duration = 'duration',
  actions = 'actions',
  log = 'log',
  case = 'case',
  project = 'project',
  tco = 'tco',
  co2FinalYear = 'co2FinalYear',
  comments = 'comments',
}

export const SCENARIO_LIST_CONFIG = new InjectionToken<ScenarioListConfig>(
  'scenario-list-config',
);
