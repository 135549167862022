import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ScenarioActionsModule } from 'prosumer-scenario/components/scenario-actions';
import { ScenarioActionsTriggerComponent } from './scenario-actions-trigger.component';

@NgModule({
  declarations: [ScenarioActionsTriggerComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    ScenarioActionsModule,
  ],
  exports: [ScenarioActionsTriggerComponent],
})
export class ScenarioActionsTriggerModule {}
