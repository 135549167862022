<div fxLayout="column" class="delete-scenarios-div">
  <h1 data-testid="title">Delete Scenarios</h1>
  <mat-list>
    <div mat-subheader>
      You are about to delete {{ scenarios.length }} scenarios:
    </div>
    <div class="delete-scenarios-list">
      @for (scenario of scenarios; track scenario.id) {
        <mat-list-item [attr.data-testid]="'scenario-' + scenario.id">
          <mat-icon color="primary" aria-hidden="false" matListItemIcon>{{
            'check'
          }}</mat-icon>
          <div matListItemTitle>{{ scenario.name }}</div>
          <div matListItemLine>
            <span>Updated at {{ scenario.updatedDate | date: format }}</span>
          </div>
        </mat-list-item>
      }
    </div>
  </mat-list>
  <div
    fxLayout="row"
    fxLayoutGap="16px"
    class="delete-scenarios-buttons"
    *ngrxLet="deleting$ as deleting"
  >
    <button
      mat-flat-button
      (click)="onDelete()"
      [disabled]="deleting"
      [class.spinner]="deleting"
      data-testid="confirm"
      color="warn"
    >
      DELETE
    </button>
    <button
      mat-button
      (click)="onCancel()"
      [disabled]="deleting"
      data-testid="cancel"
      color="primary"
    >
      CANCEL
    </button>
  </div>
</div>
