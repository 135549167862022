<div class="batch-frame">
  @if (booleanArray().length > 0) {
    <div
      fxLayout="row"
      fxLayoutGap="16px"
      fxLayoutAlign="start center"
      class="batch-div"
    >
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
        <span data-testid="count" class="batch-count">
          {{ booleanArray().length }}
        </span>
        <span class="batch-selected">scenario(s) selected</span>
      </div>
      @if (canDownload()) {
        <button
          mat-button
          color="primary"
          data-testid="download"
          (click)="onActionClick('download')"
        >
          <mat-icon
            color="primary"
            aria-hidden="false"
            aria-label="download"
            [innerHTML]="'download'"
          ></mat-icon>
          DOWNLOAD OUTPUT
        </button>
      }
      <button
        mat-button
        color="warn"
        data-testid="delete"
        (click)="onActionClick('delete')"
      >
        <mat-icon
          color="warn"
          aria-hidden="false"
          aria-label="delete"
          [innerHTML]="'delete_sweep'"
        ></mat-icon>
        DELETE
      </button>
    </div>
  }
</div>
