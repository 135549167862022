<div *ngrxLet="scenario$ as scenario">
  <mat-icon
    aria-hidden="false"
    *ngIf="shouldShowVariations(scenario); else statusIcon"
    color="primary"
    class="clickable"
    [attr.data-testid]="'scenario-list-icon-' + scenario.id"
    (click)="onVariationsClick()"
    >open_in_browser</mat-icon
  >
  <ng-template #statusIcon>
    <prosumer-simulation-status
      [status]="scenario.run?.status"
      [attr.data-testid]="'scenario-list-status-' + scenario.id"
    ></prosumer-simulation-status>
  </ng-template>
</div>
