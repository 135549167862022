import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { TooltipModule } from 'prosumer-libs/eyes-shared/modules/tooltip/tooltip.module';
import { ScenarioActionsTriggerModule } from 'prosumer-scenario/components/scenario-actions-trigger/scenario-actions-trigger.module';
import { ScenarioBatchModule } from 'prosumer-scenario/components/scenario-batch/scenario-batch.module';
import { ScenarioLogsModule } from 'prosumer-scenario/components/scenario-logs/scenario-logs.module';
import { ScenarioNameModule } from 'prosumer-scenario/components/scenario-name/scenario-name.module';
import { ScenarioStatusModule } from 'prosumer-scenario/components/scenario-status/scenario-status.module';
import { ValueModule } from 'prosumer-shared/components/value/value.module';
import { SharedModule } from 'prosumer-shared/shared.module';

import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MtxGridModule } from '@ng-matero/extensions/grid';
import { LetDirective, PushPipe } from '@ngrx/component';

import { CommentHrefComponent } from '../comment-href/comment-href.component';
import { ScenarioListComponent } from './scenario-list.component';
import {
  SCENARIO_LIST_CONFIG,
  ScenarioListConfig,
} from './scenario-list.tokens';

@NgModule({
  declarations: [ScenarioListComponent],
  imports: [
    CommonModule,
    MtxGridModule,
    LetDirective,
    PushPipe,
    SharedModule,
    MatIconModule,
    MatButtonModule,
    ScenarioBatchModule,
    ScenarioActionsTriggerModule,
    NgxSkeletonLoaderModule,
    FlexLayoutModule,
    ValueModule,
    ScenarioStatusModule,
    ScenarioLogsModule,
    ScenarioNameModule,
    TooltipModule,
    CommentHrefComponent,
  ],
  exports: [ScenarioListComponent],
})
export class ScenarioListModule {
  static forChild(
    config: ScenarioListConfig,
  ): ModuleWithProviders<ScenarioListModule> {
    return {
      ngModule: ScenarioListModule,
      providers: [{ provide: SCENARIO_LIST_CONFIG, useValue: config }],
    };
  }
}
