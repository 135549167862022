import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENV_CONFIG } from 'prosumer-app/libs/eyes-core';
import {
  ApiConfig,
  EnvConfig,
  generateEndpoint,
} from 'prosumer-app/libs/eyes-shared';
import { Scenario } from 'prosumer-scenario/models';
import { Observable } from 'rxjs';
import {
  Action,
  BatchScenariosRequest,
  BatchScenariosResponse,
  BatchedScenario,
} from './batch.models';

@Injectable({
  providedIn: 'root',
})
export class BatchService {
  constructor(
    @Inject(ENV_CONFIG) private readonly env: EnvConfig,
    private readonly http: HttpClient,
  ) {}

  batchScenarios(
    action: Action,
    scenarios: Scenario[],
  ): Observable<BatchScenariosResponse> {
    return this.http.post<BatchScenariosResponse>(
      this.buildBatchScenariosEndpoint(),
      this.buildBatchScenariosRequest(action, scenarios),
    );
  }

  private buildBatchScenariosRequest(
    action: Action,
    scenarios: Scenario[],
  ): BatchScenariosRequest {
    return {
      action,
      items: this.mapScenariosToItems(scenarios),
    };
  }

  private mapScenariosToItems(scenarios: Scenario[]): BatchedScenario[] {
    return scenarios.map((scenario) => this.mapScenarioToItem(scenario));
  }

  private mapScenarioToItem(scenario: Scenario): BatchedScenario {
    return {
      scenarioId: scenario.id,
      projectId: scenario.projectId,
      caseId: scenario.caseId,
    };
  }

  private buildBatchScenariosEndpoint(): string {
    return generateEndpoint(
      this.getBaseUrl(),
      this.getBatchScenariosEndpoint(),
    );
  }

  private getBaseUrl(): string {
    return this.configApi.baseUrl;
  }

  private getBatchScenariosEndpoint(): string {
    return this.batchEndpoints['scenario'];
  }

  private get batchEndpoints(): Record<string, string> {
    return this.configApi.endpoints['batch'];
  }

  private get configApi(): ApiConfig {
    return this.env.api || { baseUrl: '', endpoints: {} };
  }
}
