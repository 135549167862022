<button
  data-testid="trigger"
  mat-icon-button
  [matMenuTriggerFor]="menu"
  [class.spinner]="isProcessing(scenario)"
  (click)="$event.stopPropagation()"
>
  <mat-icon aria-hidden="false">more_vert</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <prosumer-scenario-actions
    data-testid="scenarioActions"
    [scenario]="scenario"
    [hideDownloadFiles]="hideDownloadFiles"
    (delete)="onDelete()"
  ></prosumer-scenario-actions>
</mat-menu>
