import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Scenario, ScenarioVariationMap } from 'prosumer-app/+scenario/models';
import { ScenarioFacadeService } from 'prosumer-app/+scenario/state';
import { RUN_STATUS_ICON_MAP } from 'prosumer-app/app.references';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'prosumer-scenario-status-dialog',
  templateUrl: './scenario-status-dialog.component.html',
  styleUrls: ['./scenario-status-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScenarioStatusDialogComponent implements OnInit {
  readonly displayedColumns: string[] = [
    'status',
    'variation',
    'launchDate',
    'duration',
    'tco',
    'co2FinalYear',
  ];
  readonly statusMap = RUN_STATUS_ICON_MAP;

  readonly data$ = new BehaviorSubject<Array<ScenarioVariationMap>>([]);
  readonly hasData$ = this.data$.pipe(map((arr) => !!arr?.length));
  readonly loading$ = new BehaviorSubject(true);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private scenarioFacade: ScenarioFacadeService,
    private matDialogRef: MatDialogRef<ScenarioStatusDialogComponent>,
    public translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.scenarioFacade.selectId(this.data.id);
    this.scenarioFacade.getScenarioVariations(this.data.id, {
      projectId: this.data.projectId,
      caseId: this.data.caseId,
    });
    this.scenarioFacade.selectedData$
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.data$.next(this.addScenarioToDisplayedData(value));
        this.loading$.next(value.loading);
      });
  }

  onClose() {
    this.matDialogRef.close();
  }

  addScenarioToDisplayedData(scenario: Scenario): Array<ScenarioVariationMap> {
    if (!!scenario.launchDate && !!scenario.scenarioVariations) {
      const mappedScenario = this.mapScenarioToDisplayedListItem(scenario);
      return [mappedScenario].concat(scenario.scenarioVariations);
    }
    return scenario.scenarioVariations;
  }

  mapScenarioToDisplayedListItem(scenario: Scenario): ScenarioVariationMap {
    return {
      variationId: '',
      name: scenario.name,
      run: { ...scenario.run, launchDate: scenario.launchDate },
    };
  }
}
