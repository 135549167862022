export interface BatchState {
  readonly loading: boolean;
}

export interface BatchScenariosRequest {
  readonly action: Action;
  readonly items: BatchedScenario[];
}

export interface BatchScenariosResponse {
  readonly totalItems: number;
  readonly totalSkipped: number;
}

export enum Action {
  delete = 'delete',
}

export interface BatchedScenario {
  readonly projectId: string;
  readonly caseId: string;
  readonly scenarioId: string;
}
