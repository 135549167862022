import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SharedModule } from 'prosumer-app/shared/shared.module';
import { ValueComponent } from './value.component';

@NgModule({
  declarations: [ValueComponent],
  imports: [CommonModule, FlexLayoutModule, SharedModule],
  exports: [ValueComponent],
})
export class ValueModule {}
