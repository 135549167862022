import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Scenario } from 'prosumer-app/+scenario/models';

@Component({
  selector: 'prosumer-scenario-actions-trigger',
  templateUrl: './scenario-actions-trigger.component.html',
  styleUrls: ['./scenario-actions-trigger.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScenarioActionsTriggerComponent {
  @Input() scenario: Scenario;
  @Input() hideDownloadFiles = true;
  @Output() delete = new EventEmitter<Scenario>();

  isProcessing(scenario: Scenario): boolean {
    const { copying, deleting, downloading, copyStatus } = scenario;
    return [copying, deleting, downloading, !!copyStatus].some(Boolean);
  }

  onDelete(): void {
    this.delete.emit(this.scenario);
  }
}
