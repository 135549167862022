import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { LetDirective, PushPipe } from '@ngrx/component';
import { ScenarioBatchComponent } from './scenario-batch.component';

@NgModule({
  declarations: [ScenarioBatchComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    LetDirective,
    PushPipe,
    MatButtonModule,
    MatIconModule,
  ],
  exports: [ScenarioBatchComponent],
})
export class ScenarioBatchModule {}
